@import 'material-icons/iconfont/material-icons.css';
@import './post-images.css';

:root {
  --body-background: #f2f2f2;
  --body-color: #444;
  --accent-color: #d01040;
  --on-accent-color: #fff;
  /* --heading-color: black; */
  --heading-color: var(--accent-color);
  --font-default: "Oxygen", "Helvetica Neue", "Helvetica", BlinkMacSystemFont,
  -apple-system, "Arial", sans-serif;
  --font-mono: "Cutive Mono", monospace;
}

body {
  background: var(--body-background);
  color: var(--body-color);
  font-family: var(--font-default);
  margin: 0;
  font-size: 108%;
  line-height: 1.5;
  min-width: 320px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-mono); 
  color: var(--accent-color);
}

a {
  /* font-family: var(--font-mono); */
  font-family: var(--font-default);
  
  color: var(--body-color);
  text-decoration: underline;
}

code, pre {
  font-family: var(--font-mono);
}

h1 {
  margin: 1rem 0 3rem;
  text-align: center;
  font-weight: 900;
  font-size: 2.5rem;
  color: var(--heading-color);
  line-height: 1.2;
}

body > header {
  margin: 1rem;
  text-align: center;
}

body > header img {
  display: inline-block;
  width: 400px;
  max-width: 100%;
}

header > a {
  text-decoration: none;
}

header h1 {
  margin-bottom: 5px;
}

header .tagline {
  margin: 0;
  font-size: 0.8em;
}

main {
  margin: 0 auto 0;
  max-width: 65rem;
  min-height: calc(100vh - 200px);
  padding: 25px 25px 50px;
  background: white;
  box-shadow: 2px 3px 3px #ddd;
  border-radius: 3px;

  @media (max-width: 500px) {
    padding: 16px 16px 50px;
  }
}

footer {
  text-align: center;
  font-size: 0.7em;
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  align-content: space-around;
}

footer .blurb {
  flex: 0 1 auto;
}

nav {
  font-size: 1em;
}

nav ul {
  /* margin: 2rem 0; */
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
  font-weight: bold;
}

nav a {
  text-decoration: none;
}

hr {
  border: none;
  border-top: 2px dotted #bbb;
  margin: 3rem 0;
}

pre {
  white-space: break-spaces;
}

/* tables */

td, th {
  padding: 1px 5px;
  text-align: start;
}

blockquote {
  border-left: 10px #ebebeb solid;
  margin-left: 0;
  padding-left: 1em;
}

li {
  line-height: 2em;
}

.alert {
  margin: 1em;
  padding: 1em 1.5em;
  border: 1px #f1f1f3 solid;
  background-color: hsl(48, 100%, 96.1%);
  border-top-color: hsl(32.1, 94.6%, 43.7%);
  border-top-width: 3px;
  border-radius: 0.35rem; 
}

.alert h3 {
  color: var(--body-color);
  margin: 0;
  text-align: initial;
  font-family: var(--font-default);
}

.post-timestamp,
.post-categories {
  font-style: italic;
  font-size: 0.8em;
}

.post-categories {
  display: flex;
  list-style-type: none;
  gap: 0.5em;
  padding: 0;
  margin-top: 0;
}
.post-categories li:after {
  content: ",";
}

.post-categories li:last-child:after {
  content: "";
}
